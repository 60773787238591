import { Suspense, lazy, useEffect } from 'react';
import { Typography } from '../../../design-system';
import { IAdvice } from './components/AdviceItem';
import Skeleton from 'react-loading-skeleton';
import SkeletonBox from './components/SkeletonBox';
import 'react-loading-skeleton/dist/skeleton.css';
import { t } from 'i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { RETRIES_NUMBER, RETRY_DELAY } from '../../AppScreens/const';
import AdvicesService from '../../../services/advices';
import { supabase } from '../../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import AdvisorService from '../../../services/advisors';
import Novu from '../../../components/notification/Notification';

const AdviceItem = lazy(() => import('./components/AdviceItem'));

const AdviceScreen = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleGetAdvices = async () => {
    const { data: sessionData } = await supabase.auth.getSession();
    const adviceService = new AdvisorService(sessionData.session);
    const { data } = await adviceService.getAdvices();

    return data;
  };

  const handleCleanAction = async (adviceId: string) => {
    const { data: sessionData } = await supabase.auth.getSession();
    const adviceServices = new AdvicesService(sessionData.session);
    const { data } = await adviceServices.cleanItem({ adviceId });
    return data;
  };

  const handleAdvisor = async () => {
    const { data: sessionData } = await supabase.auth.getSession();
    const adviceService = new AdvisorService(sessionData.session);
    const { data } = await adviceService.getAdvisorProfile();

    if (data.status !== 'active') {
      navigate(`/partner/info`, { state: { inactiveAdvisor: true } });
    }

    return data;
  };

  const { mutate: cleanAction, isLoading: loadingClear } = useMutation(
    handleCleanAction,
    {
      onSuccess: (response) => {
        queryClient.clear();
        return response;
      },
      onError: () => toast.error(t('app.general_error')),
    }
  );

  const {
    data: advices,
    isRefetching,
    isLoading,
  } = useQuery({
    queryKey: ['advices'],
    queryFn: handleGetAdvices,
    retryDelay: RETRY_DELAY,
    retry: RETRIES_NUMBER,
  });

  const { isLoading: loadingAdvisor } = useQuery({
    queryKey: ['advisor'],
    queryFn: handleAdvisor,
    retryDelay: RETRY_DELAY,
    retry: RETRIES_NUMBER,
  });

  useEffect(() => {
    handleAdvisor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isRefetching || isLoading || loadingClear || loadingAdvisor) {
    return (
      <div className="flex justify-center w-full">
        <div className="flex flex-col w-spacing-610 mt-spacing-72">
          <Typography variant="HeadingDefault600">
            {t('advice.title')}
          </Typography>
          <div className="mt-spacing-64">
            <Skeleton
              count={5}
              height={64}
              wrapper={SkeletonBox}
              borderRadius={12}
            />
          </div>
        </div>
      </div>
    );
  }

  if (!advices?.length) {
    return (
      <div className="flex justify-center w-full">
        <div className="flex flex-col w-spacing-610 mt-spacing-72">
          <Typography variant="HeadingDefault600">
            {t('advice.title')}
          </Typography>
          <Typography
            variant="LabelSmall400"
            className="mt-spacing-64 text-gray-700"
          >
            {t('advice.sub_title_advisor')}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center w-full">
      <div className="flex flex-col w-spacing-610 mt-spacing-72">
        <div className="flex justify-between items-center">
          <Typography variant="HeadingDefault600">
            {t('advice.title')}
          </Typography>
          <Novu />
        </div>
        <div className="w-full flex px-spacing-32 mb-spacing-12 mt-spacing-64 items-center">
          <div className="flex flex-1">
            <div className="flex-1 w-2/4">
              <div className="flex">
                <Typography variant="LabelSmall400">
                  {t('advice.author_name')}
                </Typography>
              </div>
            </div>
            <div className="flex-1 w-2/4">
              <div className="flex">
                <Typography variant="LabelSmall400">
                  {t('advice.countries')}
                </Typography>
              </div>
            </div>
          </div>
          <div className="flex justify-end w-[91px]">
            <Typography variant="LabelSmall400">
              {t('advice.status')}
            </Typography>
          </div>
        </div>
        <Suspense
          fallback={
            <Skeleton
              count={5}
              height={72}
              wrapper={SkeletonBox}
              borderRadius={12}
            />
          }
        >
          {advices?.map((advice: IAdvice) => (
            <div className="mb-spacing-24" key={advice?.id}>
              <AdviceItem
                {...advice}
                actionClean={() => {
                  cleanAction(advice?.id);
                }}
              />
            </div>
          ))}
        </Suspense>
      </div>
    </div>
  );
};

export default AdviceScreen;
