import invoices from './en/invoices';
import languages from './en/languages';
import partnerinfo from './en/partnerinfo';

const en = {
  go_back: 'Go back',
  selectCountry: 'Select a country',
  searchCountry: 'Search country',
  noCountryFound: 'No country was found',
  selectLanguage: 'Select a language',
  searchLanguage: 'Select a language',
  noLanguageFound: 'No language was found',
  addLanguage: 'Add a language',
  or_press: 'Or press',
  enter: 'Enter',
  previous_question: 'Previous question',
  previous_section: 'Back',
  next_section: 'Next',
  submit: 'Submit',
  languages_lbl: 'Languages',
  breadrumb: {
    client: {
      home: 'Home',
    },
    advisor: {
      home: 'Clients',
    },
  },
  sidebar: {
    general: {
      label: 'General',
      name_and_email: 'Name and Email',
      address: 'Address',
      family: 'Family',
      personal_details: 'Personal Details',
    },
    countries: {
      label: 'Countries',
    },
    wrapping_up: {
      label: 'Wrapping up',
      name_and_email: 'Name and Email',
      address: 'Address',
      family: 'Family',
      personal_details: 'Personal Details',
    },
  },
  output: {
    titles: {
      one_very_high:
        'For {{year}}, this is your most likely country of tax residence',
      multiple_very_high:
        'You could be a tax resident of more than one country in {{year}}',
      one_high:
        'For {{year}} it wasn’t all that clear, but it’s likely that your tax residence is in this country',
      multiple_high:
        'In {{year}} it seems to be a close call between these countries',
    },
    show_results: 'Show me the results',
    action_container: {
      title: 'Start filing taxes worldwide, the smart way',
      subtitle: 'No cure, no pay',
      email_label: 'Your email',
      get_started: "Let's get started",
      features: {
        orchestration: 'Orchestrating your worldwide tax filings',
        transparent: 'One transparent fee upfront',
        experts: 'Experts everywhere, selected by Tytle',
        complementation: 'Complementing your local tax advisor if needed',
        data: 'Data handled securely',
      },
    },
  },
  last_verify: {
    title: 'Pick a complexity level, and check the fee estimates',
    subtitle:
      'Below you find the overview of all the countries where you have indicated that you may or may not do a tax filing with Tytle for the tax year {{fiscalYear}}. If you choose to work with a Tytle taxpert for a specific country we will make sure that the final price for that country reflects your situation, based on the information that you have provided us thus far.\n\nIf you agree with the broad strokes of the pricing below you can click “Schedule a call”. When you have scheduled a call we will immediately reach out to our taxpert(s) to get a more accurate price indication for your case, which we can discuss on the call with you. In any case, you will only submit a 20% deposit to get started.\n\nShowing the prices in {{currency}}. Change currency by clicking <u>here.</u>',
    tags: ['low complexity', 'medium complexity', 'high complexity'],
    resident_countries: 'Resident filings',
    non_resident_countries: 'Non-resident filings',
    case_dependent: 'Case dependent*',
    out_of_network: 'On request, out of network**',
    average_price: 'Average price of {{amount}}',
    schedule_call: 'Schedule a call',
    own_taxpert: 'Using own tax expert†',
    flat_fee: 'Flat fee of {{amount}}',
    starting_from: 'Starting from {{amount}}',
    case_dependent_obs_text:
      '* Tax expert has to provide a fee quote, based on your information.',
    out_of_network_obs_text:
      '** This country is not supported (yet), but we can try to find an expert and a fee quote for you.',
    own_taxpert_obs_text: '† Please contact your own tax expert for fees.',
  },
  verify: {
    taxResidenceKnown: {
      title: 'Let’s confirm your tax residence - it’s important',
      text1:
        'You indicated that your country of tax residence for tax year {{fiscalYear}} was {{country}}. Please confirm that you’re sure about this.',
      text2:
        '\nWhy is that important?\n\nIn the next part, we’re going to ask questions about your income and assets. These questions are specific to the selected country of tax residence. In other words, from a tax resident of Italy (for instance) we sometimes want to know other things than from a tax resident of Finland, or the US. If you change the selected tax residence later on, you’ll have to answers all questions about income and assets again. That’s why we want to double check.',
      text3:
        'In the next part, we’re going to ask questions about your income and assets. These questions are specific to the selected country of tax residence. In other words, from a tax resident of Italy (for instance) we sometimes want to know other things than from a tax resident of Finland, or the US. If you change the selected tax residence later on, you’ll have to answers all questions about income and assets again. That’s why we want to double check.',
      submit: 'I confirm my selection',
    },
    simple: {
      title: 'Let’s confirm your tax residence - it’s important',
      text1:
        'Based on your answers, you were most likely tax resident of {{country}} for tax year {{fiscalYear}}. Please confirm you want to go ahead with {{country}} as country of tax residence.',
      text2:
        '\nWhy is that important?\n\nIn the next part, we’re going to ask questions about your income and assets. These questions are specific to the selected country of tax residence. In other words, from a tax resident of Italy (for instance) we sometimes want to know other things than from a tax resident of Finland, or the US. If you change the selected tax residence later on, you’ll have to answer all questions about income and assets again. That’s why we want to double check.',
      submit: 'I confirm the result',
    },
    complex_one: {
      title: 'Let’s confirm your tax residence - it’s important',
      text1:
        'Based on your answers, you were most likely tax resident of {{country}} for tax year {{fiscalYear}}. However, your input suggests that your situation could be more complex than average. Please confirm you want to go ahead with {{country}} as the country of tax residence even though this outcome could change.',
      text2:
        '\nWhy is that important?\n\nIn the next part, we’re going to ask questions about your income and assets. These questions are specific to the selected country of tax residence. In other words, from a tax resident of Italy (for instance) we sometimes want to know other things than from a tax resident of Finland, or the US. If you change the selected tax residence later on, you’ll have to answer all questions about income and assets again.\n\nIf you’re sure about this outcome, then please click “I confirm” to proceed. If you’re unsure, just schedule a 15-minute call with us and we’ll double check together with you.',
      actions: {
        confirm: 'I confirm',
        call: 'Call Tytle',
      },
      submit: 'Continue',
    },
    complex_multiple: {
      title: 'Let’s confirm your tax residence - it’s important ',
      text1:
        'Your input suggests that your tax residence for tax year {{fiscalYear}} is more complex than average. Therefore we want to double check with you.',
      text2:
        'If you know what your country of tax residence is, please choose “self select”. If you’re unsure, just schedule a 15-minute call with us and we’ll review together with you.\n\nWhy is that important?\n\nIn the next part, we’re going to ask questions about your income and assets. These questions are specific to the selected country of tax residence. In other words, from a tax resident of Italy (for instance) we sometimes want to know other things than from a tax resident of Finland, or the US. If you change the selected tax residence later on, you’ll have to answer all questions about income and assets again.',
      actions: {
        confirm: 'Self select',
        call: 'Call Tytle',
      },
      submit: 'Continue',
    },
    very_complex: {
      title: 'Let’s confirm your tax residence - it’s important ',
      text1:
        'Your input suggests that your tax residence is highly complex. Since the upcoming questions will be based on your tax residence, it’s necessary that the starting point is correct. After all, we don’t want you to spend time on the wrong questionnaire.',
      text2:
        'Please schedule a 15-minute call with us so we can look at your tax residence together. After scheduling, click "Continue”.',
      submit: 'Continue',
    },
    in_progress: {
      title: 'You’ve already scheduled a call with Tytle',
      text1:
        'If you want to cancel or change the date of the scheduled call, please go to the e-mail that was sent to you and follow the instructions.',
      text2:
        'If you need to schedule a second appointment, please click on the button below.',
      submit: 'Continue',
      schedule: 'Schedule a call with Tytle',
    },
    done: {
      title:
        'Your country of tax residence for {{fiscalYear}} has been selected',
    },
  },
  call_us: {
    title: 'Schedule a call with a Tytle expert',
    text: 'After you have scheduled your call, please click “Continue”.',
    schedule: 'Schedule a call with Tytle',
    submit: 'Continue',
  },
  select_country: {
    title:
      'In {{fiscalYear}}, in which of these {{countries}} countries were you tax resident?',
    text: "If the country you selected doesn’t turn out to be your country of tax residence, you'll need to answer all questions again. If you're unsure, it's best to schedule a call with us before proceeding.",
    submit: 'Continue',
  },
  taxResidenceKnown: {
    title:
      'For the year {{fiscalYear}} you self-selected your country of tax residence',
  },
  questions: {
    tax_residence_known: {
      label: 'Tax residence',
      title:
        'Do you know in which country you have your tax residence for year {{fiscalYear}}?',
      description:
        'Please note that tax residence rules can be more complicated than you think. It’s not always about spending more (or less) than 183 days in a country. If you’re not sure, it’s safer to select ‘no’.',
      lightbox: {
        title: 'How do I know my tax residence?',
        text: 'Tax residence refers to your home country (or primary country) for tax purposes. This country claims the right to tax you on your worldwide income and assets.  A person is usually considered a tax resident in a country if they spend a significant amount of time there or if they have other ties with that country, such as a primary home or significant social and economic interests. Note that a stay of 183 days in a country can trigger tax residence, but the rules are often more complex and vary from country to country.',
      },
      answers: [
        { label: 'Yes' },
        { label: 'No' },
        { label: 'Unsure, help me find out' },
      ],
      aditionalQuestions: [
        {
          label: 'Select country',
          title:
            'Please select the country in which you were tax resident in {{fiscalYear}}',
          answers: [],
        },
        {
          label: 'Confirm Fiscal Year',
          title: 'Select the tax year you were a resident of {{country}}',
          answers: [],
        },
      ],
    },
    tax_residence_known_confirmation: {
      label: 'Confirm tax residence',
      title:
        'You indicated that you already know where you’re tax resident. Do all of the following apply to you?',
      description: `• Permanent home only in one country;
         • Social and economic activities (nearly) all in one country;
         • No relevant connections or ties with other countries.\n\n
         If not, we strongly advise you go back and select “Let’s find out where I’m tax resident”.`,
      answers: [],
    },
    consent_pop_up: {
      title: 'Are you sure?',
      subtitle:
        'By changing your answer to a question, every subsequent question will be reset. Are you sure you want to continue?',
      yes: 'Yes',
      no: 'No',
    },
    fiscal_year: {
      label: 'Tax year',
      description: `We'll go through a few questions to determine your most likely country of tax residence. We’ll start with the bigger picture before going to the selected countries. This is important because tax residence is often based on various facts and circumstances. As you’ll see, there’s much more to it than just counting the days spent in a country.`,
      title:
        'For what <span data-tooltip="the calendar year">year</span> do you want to determine your tax residence?',
      year_description: 'Tax year',
      lightbox: {
        title: 'Note: this is a simulation',
        text: 'Please note that you’re about to use a simulation tool for tax residence. The results of this simulation show you what relevant factors are in determining tax residence. Always seek expert advice to see how this simulation aligns with, or differs from, your personal situation. Without verification by an expert, this tool cannot be relied upon for tax filing or tax planning purposes.',
      },
      taxResidenceLB: {
        title: 'Tax residence',
        text: 'Tax residence refers to your home country (or primary country) for tax purposes. This country claims the right to tax you on your worldwide income and assets.  A person is usually considered a tax resident in a country if they spend a significant amount of time there or if they have other ties with that country, such as a primary home or significant social and economic interests. Note that a stay of 183 days in a country can trigger tax residence, but the rules are often more complex and vary from country to country.',
      },
    },
    countries: {
      label: 'Visits',
      title:
        'Let’s start figuring out your tax residence. During the calendar year {{fiscalYear}}, in which countries did you stay more than 30 days?',
      description:
        'For the calendar year {{fiscalYear}}, in which countries did you <span data-tooltip="Take into account any type of stay, regardless of the reason (personal, professional or otherwise). It does not matter if the days were in a row or spread out over the year.">stay</span> more than 30 days?',
      answers: [],
    },
    passport: {
      label: 'Nationality',
      title: 'From which country (countries) are you a national?',
      answers: [
        { label: 'Brazil' },
        { label: 'United States' },
        { label: 'Spain' },
        { label: 'Portugal' },
      ],
      lightbox: {
        title: 'Why does this matter?',
        text: 'Countries determine if you’re a tax resident based on various facts and circumstances. Nationality can then be a relevant factor, also when you’re considered a tax resident by two countries at the same time.',
      },
    },
    immigration: {
      label: 'Emigration',
      title_regular: 'Did you emigrate to another country in {{fiscalYear}}?',
      title_irregular:
        'Did you emigrate to another country in between {{earliestDate}} and {{latestDate}}?',
      description:
        'Emigrating means leaving one country and start living in another, with the intention to make it your new (non-temporary) home. This often means that you do one (or more) of the following: terminate your lease, rent out your home, sell your home, deregister as a resident, terminate memberships (gym, club, library etc.), sell your car, find a new school for your children. In the country to which you emigrate you do the opposite (starting a lease, buying a home etc.)',
      answers: [{ label: 'Yes' }, { label: 'No' }],
      aditionalQuestions: [
        {
          label: 'Date',
          title: 'What was the (approximate) date you emigrated?',
          answers: [
            {
              label: 'Enter date',
              error: 'Invalid date',
              notBetweenError:
                'Must be between {{earliestDate}} and {{latestDate}}',
            },
          ],
        },
        {
          label: 'Origin',
          title: 'From which country did you move?',
          answers: [],
        },
        {
          label: 'Destination',
          title: 'Which country did you move to?',
          answers: [],
        },
      ],
    },
    previous_tax_return: {
      label: 'Previous tax return',
      title:
        'In which country did you file a <span data-tooltip="In most countries you can file either a non-resident tax return or a resident tax return, depending on your status. We’re looking for the resident tax return: the ‘primary’ tax return that relates to your worldwide income and assets.">resident tax return</span> for the year {{fiscalYear}}?',
      answers: [{ label: 'I have not filed (yet)' }],
    },
    irregular_fy: {
      label: 'Irregular tax year',
      title:
        'The tax year of {{country}} is different from the calendar year. Please select and/or verify in which tax year(s) you were a tax resident of {{country}}.',
    },
    historic_ties: {
      label: 'Life-long ties',
      title:
        'With which country do you have the strongest life-long, <span data-tooltip="Other examples that point to a life-long, personal relationship: you went to school there, you speak the country’s language(s) as a native speaker, you built a career there, you filed most of your resident tax returns there.">personal relationship</span>?',
      description:
        'This is usually the country were you were born, raised and/or spent most years of your life.',
      answers: [],
    },
    permanent_home: {
      label: 'Permanent home',
      title_irregular:
        'In which country or countries did you have a <span data-tooltip="Some examples to get a better idea of what a permanent home means: a house (or apartment, or similar) you own without tenants in it; a house that you rent long-term; a shared house where you always have a room at your disposal. The home must be used for permanent and continuous use.">permanent home</span> between {{earliestDate}} and {{latestDate}}?',
      title_regular:
        'In which country or countries did you have a <span data-tooltip="Some examples to get a better idea of what a permanent home means: a house (or apartment, or similar) you own without tenants in it; a house that you rent long-term; a shared house where you always have a room at your disposal. The home must be used for permanent and continuous use.">permanent home</span> in the year {{fiscalYear}}?',
      description:
        'A permanent home is a place where you can stay and that is always available to you. It’s not important whether you own or rent it.',
      answers: [
        { label: "I didn't have a permanent home between theses dates" },
      ],
    },
    stay: {
      label: 'Stay',
      title_regular:
        'How long did you <span data-tooltip="Take into account any type of stay, regardless of the reason (personal, professional or otherwise). It does not matter if the days were in a row or spread out over the year.">stay</span> in {{country}} in {{fiscalYear}}?',
      title_irregular:
        'How long did you <span data-tooltip="Take into account any type of stay, regardless of the reason (personal, professional or otherwise). It does not matter if the days were in a row or spread out over the year.">stay</span> in {{country}} {{textDateByCountry}}?',
      answers: [
        { label: 'I didn’t stay here' },
        { label: '6 months (183 days) or less' },
        { label: 'More than 6 months' },
      ],
      aditionalQuestions: [
        {
          label: 'Number of months',
          title:
            'Let’s narrow that down. Approximately how many months did you stay in {{country}}?',
          answers: [
            { label: 'less than 2 months' },
            { label: '2 months' },
            { label: '3 months' },
            { label: '4 months' },
            { label: '5 months' },
            { label: '6 months' },
          ],
        },
        {
          label: 'How many days',
          title: 'How many days did you stay in {{country}} {{text}}?',
          description:
            'As you answered approximately 6 months, it could be important to know the exact number of days in determining where you have your tax residence.',
          answers: [
            { label: 'Less than 183 days' },
            { label: '183 days' },
            { label: 'More than 183 days' },
            { label: 'I don’t know exactly' },
          ],
        },
      ],
    },
    recap: {
      label: 'Recap',
      title: 'Recap',
      text1:
        'Looking at the calendar year {{fiscalYear}}, the fiscal years of one or more countries have not ended yet. Tax year that started in the second half of {{fiscalYear}} and that have not finished yet will be excluded. These are: ',
      text2:
        'For fiscal years starting in the second half of a calendar year we cannot have enough information to make an accurate assessment.',
      text3:
        'Now we will ask you questions specifically about the following countries:',
      country_to_be_excluded: 'Tax year {{fiscalYear}} for {{country}}.',
      lightbox: {
        title: 'Why do we include or exclude certain combinations?',
        text: 'Since tax residence assessments can only take place once the tax year is completed, we have to exclude tax years that are still ongoing. Note that some countries have an irregular tax year, meaning that it does not fully overlap with a calendar year.',
      },
    },
    employment: {
      label: 'Employment',
      title:
        'While you stayed there, did you work for an <span data-tooltip="“Working for an employer” can relate to many different activities. For example: you were a paid intern, you were seconded to {{country}}, you worked for an international company that has a presence in {{country}}, or you worked for a local small business.">employer</span> based in {{country}}?',
      description:
        'Please select ‘yes’ if this applied to you during a part of your stay or the entire stay.',
      answers: [{ label: 'Yes' }, { label: 'No' }],
    },
    ties: {
      label: 'Ties',
      title:
        'During the relevant tax year, what ties did you have with {{country}}?',
      description:
        'Only select ties that you had during (a part of) the relevant tax year.',
      answers: [
        { label: 'household', description: 'partner, children' },
        { label: 'other relatives', description: 'parents, siblings, cousins' },
        {
          label: 'membership',
          description: 'gym, political party',
        },
        {
          label: 'subscriptions',
          description: 'mobile plan, newspaper',
        },
        { label: 'bank account', description: 'debit, savings' },
        { label: 'vehicle', description: 'car, motor' },
        { label: 'doctor', description: 'family doctor, dentist, specialist' },
        { label: 'insurance', description: 'health, home, car, liability' },
      ],
    },
    residency: {
      label: 'Residence',
      title_regular:
        'Were you a <span data-tooltip="You do this by letting the authorities know you moved to that country and what your address is. This could either be an online process or something you communicate in person to a national or local authority. Registration as a resident may also be part of an application for a tax number or social security number.">registered resident</span> of {{country}} in {{year}}?',
      title_irregular:
        'Were you a <span data-tooltip="You do this by letting the authorities know you moved to that country and what your address is. This could either be an online process or something you communicate in person to a national or local authority. Registration as a resident may also be part of an application for a tax number or social security number.">registered resident</span> in {{country}} {{textDateByCountry}}?',
      answers: [{ label: 'Yes' }, { label: 'No' }, { label: 'Unsure' }],
    },
    visa: {
      label: 'Visa',
      title: 'What type of visa did you have during your stay in {{country}}?',
      description:
        'Perhaps you still have that same visa, another visa, or no visa. What counts is the type of visa you had during your stay. ',
      answers: [
        { label: 'Immigrant or permanent visa' },
        { label: 'Tourist visa, non-immigrant visa, or other temporary visa' },
        { label: 'No visa' },
      ],
    },
    personal_ties: {
      label: 'Personal Ties',
      title:
        'Between these countries, with which country did you have the strongest personal and economic ties between {{earliestDate}} and {{latestDate}}?',
      answers: [
        {
          label: 'None in particular',
        },
      ],
    },
    permanent_home_country: {
      label: 'Permanent home',
      title_regular:
        'Did you have a <span data-tooltip="Examples are: a house you own without having renters in it, or a house / apartment that you rent long-term, a shared house where you always have a room at your disposal, or a second home that is always available to you.">permanent home</span> in {{country}} {{textDateByCountry}}?',
      title_irregular:
        'Did you have a <span data-tooltip="Examples are: a house you own without having renters in it, or a house / apartment that you rent long-term, a shared house where you always have a room at your disposal, or a second home that is always available to you.">permanent home</span> in {{country}} in {{fiscalYear}}?',
      description:
        'A permanent home is a place where you can stay that is always available to you.',
      answers: [{ label: 'Yes' }, { label: 'No' }],
    },
    personal_description: {
      label: 'Personal Description',
      title:
        'We’d like to know who’s interested in determining their tax residence. Could you tell us a bit more about yourself?',
      description: 'What best describes you? (select all that apply)',
      answers: [
        { label: 'digital nomad' },
        { label: 'entrepreneur' },
        { label: 'expat' },
        { label: 'startup employee' },
        { label: 'tax optimizer' },
        { label: 'crypto investor' },
        { label: 'other (please enter)' },
      ],
    },
    detailed_description: {
      label: 'Detailed Description',
      title: 'As you selected “other”, please enter how you describe yourself:',
      description: 'What best describes you?',
      answers: [{ label: 'Other' }],
    },
  },
  checkout: {
    title: 'Please submit the deposit payment',
    box_text:
      'Your payment details will not be stored on the Tytle platform, and they will als not be stored by Stripe, the payment partner we work with to process your payment',
    button_pay: 'Submit deposit payment of {{currency}} {{amount}}',
    text_after_box:
      'After submitting the deposit we will immediately get to work!\nThe first step will be connecting you with the right Tytle tax experts with\nwhom you will sign an engagement agreement',
    failed: 'Failed payment, please try again.',
  },
  result_detail: {
    placeholder_c: 'PLACEHOLDER C',
    taxes_description: 'Find out more and file your worldwide taxes',
    lets_get_started: 'Let’s get started =>',
    x_complications: '{{number}} complications and warnings',
    button_more: '{{more}} more',
    button_continue: 'Continue with year {{year}}',
    titles: {
      one_taxresfn_default_trigger:
        'You met the criteria for tax residence in {{winningCountry}}. No other country came close.',
      one_taxresfn_trigger:
        'You met the criteria for tax residence in {{winningCountry}}. No other country came close.',
      two_countries_one_ph_dta_resolution:
        'You met the criteria for tax residence in {{country1}} and {{country2}}, if both countries were to independently claim your tax residence you could point to the in-effect tax treaty that states that your tax residence is in {{winningCountry}} because you had a permanent home there',
      two_countries_dta_resolution:
        'You met the criteria for tax residence in {{country1}} and {{country2}}, if both countries were to independently claim your tax residence you could point to the in-effect tax treaty that states that your tax residence is in {{winningCountry}}',
      more_countries_no_ph_dta_resolution:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}}, but if all countries were to independently claim your tax residence you could point to the in-effect tax treaty that states that your tax residence is in {{winningCountry}}',
      more_countries_one_ph_dta_resolution:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}}, but if all countries were to independently claim your tax residence you could point to the in-effect tax treaty that states that your tax residence is in {{winningCountry}} because you had a permanent home there',
      more_countries_two_ph_dta_resolution:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}}, but if all countries were to independently claim your tax residence you could point to the in-effect tax treaty that states that your tax residence is in {{winningCountry}}',
      more_countries_three_ph_dta_resolution:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}}, but if all countries were to independently claim your tax residence you could point to the in-effect tax treaty that states that your tax residence is in {{winningCountry}}',
      two_countries_no_tax_treaty:
        'You met the criteria for tax residence in {{country1}} and in {{country2}}, and if both countries were to independently claim your tax residence you risk being subject to having a double tax residence and therefore paying tax twice on some income',
      two_countries_no_dta_resolution:
        'You met the criteria for tax residence in {{country1}} and in {{country2}}, and if both countries were to independently claim your tax residence it is likely that the in-effect tax treaty does not provide a resolution and that the two countries have to come to a mutual agreement on your case',
      more_countries_no_ph_dta_resolution_multiple_winners:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}} and if all countries were to independently claim your tax residence it is likely that the in-effect tax treaties between these countries does not give one unified outcome and that they have to come to a mutual agreement on your case',
      more_countries_no_ph_no_dta_resolution:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}} and if all countries were to independently claim your tax residence it is likely that the in-effect tax treaties between them does not provide a resolution and that the countries have to come to a mutual agreement on your case',
      more_countries_no_ph_some_dta_resolution:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}} and if all countries were to independently claim your tax residence it is likely that only some of the in-effect tax treaties between these countries would provide a resolution',
      more_countries_no_ph_no_tax_treaty:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}} and if all countries were to independently claim your tax residence you risk being subject to having multiple tax residences and therefore paying tax multiple times on some income',
      more_countries_no_ph_some_tax_treaty:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}} and if all countries were to independently claim your tax residence you risk being subject to having multiple tax residences and therefore paying tax multiple times on some income',
      more_countries_one_ph_no_tax_treaty:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}} and if all countries were to independently claim your tax residence you risk being subject to having multiple tax residences and therefore paying tax multiple times on some income',
      more_countries_one_ph_some_tax_treaty:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}} and if all countries were to independently claim your tax residence you risk being subject to having multiple tax residences and therefore paying tax multiple times on some income',
      more_countries_two_ph_no_dta_resolution:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}} and if all countries were to independently claim your tax residence it is likely that the in-effect tax treaties between them does not provide a resolution and that the countries have to come to a mutual agreement on your case',
      more_countries_two_ph_no_tax_treaty:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}} and if all countries were to independently claim your tax residence you risk being subject to having multiple tax residences and therefore paying tax multiple times on some income',
      more_countries_two_ph_some_tax_treaty:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}} and if all countries were to independently claim your tax residence you risk being subject to having multiple tax residences and therefore paying tax multiple times on some income',
      more_countries_three_ph_dta_resolution_multiple_winners:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}} and if all countries were to independently claim your tax residence it is likely that the in-effect tax treaties between them does not provide a unified outcome and that the countries have to come to a mutual agreement on your case',
      more_countries_three_ph_no_dta_resolution:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}} and if all countries were to independently claim your tax residence it is likely that the in-effect tax treaties between them does not provide a resolution and that the countries have to come to a mutual agreement on your case',
      more_countries_three_ph_some_dta_resolution:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}} and if all countries were to independently claim your tax residence it is likely that only some of the in-effect tax treaties between these countries would provide a resolution',
      more_countries_three_ph_no_tax_treaty:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}} and if all countries were to independently claim your tax residence you risk being subject to having multiple tax residences and therefore paying tax multiple times on some income',
      more_countries_three_ph_some_tax_treaty:
        'You met the criteria for tax residence in {{contriesList}} and in {{lastCountry}} and if all countries were to independently claim your tax residence you risk being subject to having multiple tax residences and therefore paying tax multiple times on some income',
      one_country_no_ph_other_reasons:
        'You did not meet the criteria for tax residence in any country, but looking at your ties to {{country}} it is likely that your tax residence is here',
      one_country_one_ph_only_ph:
        'You did not meet the criteria for tax residence in any country, but looking at your ties to {{country}} and the fact that you only have a permanent home in this country it is likely that your tax residence is in here',
      more_country_no_ph_other_reasons:
        'You did not meet the criteria for tax residence in any country, and because you seem to have some ties to more than one country it will be a subjective call',
    },
    box_title: {
      simple: 'Your result is quite straightfoward',
      complex: 'Your result is more complex than average',
      very_complex: 'Your result is very complex',
    },
    warnings: {
      simple_one: {
        title: 'However, we found ',
        complexity: '{{number}} complexity',
      },
      simple_more_one: {
        title: 'However, we found ',
        complexity: '{{number}} complexities',
      },
      complex_zero: {
        title: 'We can figure it out with you',
        complexity: '',
      },
      complex_one: {
        title: 'We found ',
        complexity: '{{number}} complexity',
      },
      complex_more_one: {
        title: 'We found ',
        complexity: '{{number}} complexities',
      },
    },
    flags: {
      irregular_fiscal_year: {
        label: 'Irregular tax year',
        text: 'An irregular tax year, not fully overlapping with a calendar year, can pose difficulties for you in cross-border situations, making tax residence assessment and compliance more challenging. The mismatch between the tax year and the calendar year can make it harder to determine your tax residence and fulfill your tax obligations.',
      },
      citizenship_based_tax: {
        label: 'Nationality-based taxation',
        text: "Nationality-based taxation is a system where a person's tax obligations are determined by their nationality, rather than their place of residence or source of income. In a nationality-based tax system, a person may be liable to pay taxes in their home country even if they live and work abroad, based on the principle that they are still a national of that country. It typically leads to additional compliance requirements.",
      },
      migration: {
        label: 'Migration',
        text: "Moving to a new country can have an impact on your tax obligations. You may become a tax resident in the new country and be subject to its tax laws and regulations. If you move mid-year, you may face split-year tax residence, where you're considered a resident in both your previous and new countries, leading to complex tax reporting and compliance requirements.",
      },
      days_stay: {
        label: 'Multiple tax residence claims',
        text: 'Having multiple countries claiming tax residence based on their national rules can create complexity in your tax filing. Different countries may have different criteria for determining tax residence, such as the number of days spent in the country or whether your social and economic life is based mostly there (the so-called “center of vital interests”). Simply owning a house can also trigger tax residence in a country. This can result in conflicting tax residence claims, making it difficult to determine where you are considered a tax resident and what your tax obligations are in each country. This, in turn, can lead to complex tax reporting and compliance requirements, and potentially increase your risk of double taxation.',
      },
      window_tax_filing_deadline: {
        label: 'Tax filing window disjoint',
        text: '',
      },
      multiple_intention_migrate: {
        label: 'Dual residence',
        text: 'Having dual residence or strong ties to multiple countries can result in a complex tax situation because it can be difficult to determine of which country you should be considered a resident for tax treaty purposes. This is a crucial factor in determining your tax obligations, as it affects which country has the right to tax your worldwide income.',
      },
      flight_to_file: {
        label: 'No designated tax residence',
        text: 'Moving and working between multiple countries without a designated tax residence complicates tax filings, since different countries may claim your tax residence. This can also impact the ease of related tasks such as opening bank accounts.',
      },
      high_likelihood_no_dta: {
        label: 'No treaty',
        text: "Some countries for which you have a high likelihood of tax residence don't have a DTA in place between each other, increasing the risk of double taxation, since you'll be subject to tax on your worldwide income in both countries. If there is no DTA in place, each country's domestic tax laws will apply, which can result in different tax obligations and liabilities in each country.",
      },
    },
    likelihood: {
      very_low: 'Very low',
      low: 'Low',
      high: 'High',
      very_high: 'Very high',
    },
  },
  tax_year: {
    title: 'Tax year {{year}}',
    description:
      'Once you have completed and submitted all the items below, Tytle’s team of international experts will begin preparing your taxes for you to review.',
    collapses: {
      up_next: 'up next',
      tax_residence: {
        title: '1. Determine tax residence',
        cards: {
          residence: 'Residence questionnaire',
          verify: 'Verify and confirm',
        },
        time: '10 - 15 mins',
      },
      income: {
        title: '2. Your income and assets',
        cards: {
          residence: 'Income and assets questionnaire',
        },
        time: '15 - 20 mins',
      },
      extra: {
        title: '3. Extra filing information',
        cards: {
          select: 'Select countries and options',
          additional: 'Complexities',
        },
        time: '5 - 10 mins',
      },
      review: {
        title: '4. Review and send to expert',
        cards: {
          verify_tytle: 'Verify with Tytle',
          submit: 'Submit payment and start filing',
        },
        time: '5 - 10 mins',
      },
    },
    card_status: {
      to_do: 'To do',
      up_coming: 'Upcoming...',
      disabled: 'Upcoming...',
      done: 'Done',
      in_progress: 'In progress',
      deposit_submitted: 'In progress',
      under_review: 'Under review',
    },
    card_progress: {
      in_progress:
        'We’re currently waiting for your deposit payment in order to proceed with the tax filing process.',
      under_review:
        'Your documents have been submitted and have been sent to the tax expert(s). They are currently reviewing your submission. Please check back here for updates.',
      done_submitted:
        'We received your deposit. Tytle will soon send you a link to a portal where you can upload documents and answer outstanding questions that are country specific. You will be invited to this portal by email, but you can also find this portal at the top of this page after you have been invited. After completing the questions and relevant uploads we will update this page so you can track the progress.',
      vault: '{{country}} document vault',
      done: 'Your tax filings have been submitted successfully! If you have any remaining questions about the respective tax filings submitted you can click on the "Help" button in the top right corner to start a chat with the Tytle support team.',
    },
    modal_confirm: {
      title: 'Are you sure you want to delete?',
      subtitle: 'All your progress will be lost',
      confirmText: 'Yes',
      cancelText: 'No',
    },
  },
  advice: {
    answers_title: 'Request',
    title: 'Clients',
    sub_title:
      'Please click on the button below to start a new tax advice request.\nAfter submitting, Tytle will review and get back to you as soon as possible.',
    sub_title_advisor:
      'You have been successfully linked as a Tytle advisor. New client requests will appear on this page.',
    button: 'New advice',
    start_button: 'New advice request',
    label: 'label',
    author_name: 'name',
    countries: 'countries',
    country: 'country',
    status: 'status',
    statuses: {
      questions_to_finish: 'incomplete',
      questions_to_answer: 'incomplete' /** temp */,
      incomplete: 'incomplete',
      in_progress: 'in progress',
      in_review_tytle: 'in review by Tytle',
      archived: 'archived',
      done: 'done',
      new: 'new',
      accepted: 'accepted',
      finalize_payment: 'to finalize payment',
      expired: 'expired',
      closed: 'closed',
      outstanding: 'outstanding',
      past_due: 'past due',
      paid: 'paid',
      void: 'void',
      scheduled: 'payment due',
      charged: 'paid',
      fake: 'pending',
      invoice_requested: 'invoice requested',
    },
  },
  advice_details: {
    overview: {
      dossier: {
        title: 'Dossier',
        error: 'There was an error trying to fetch the Dossier!',
        retry: 'Retry',
        empty: 'No annotations available!',
        view_questionnaire: 'view questionnaire',
        click_expand: 'Click to expand',
      },
      topic: {
        title: 'Case name',
        status: {
          in_progress: 'in progress',
        },
        start_new_lbl: 'Start request',
        start_new_btn: 'Start request',
        continue_lbl: 'Finish request',
        continue_btn: 'Finish request',
      },
      request: {
        title: 'Immigration to Portugal advice on NHR on other income',
        button: 'initial advice request',
      },
      latest_updates: {
        title: 'Latest updates',
        button: 'see all',
        status: {
          message: 'You have {{number}} new message',
          proposal: 'New proposal to review',
          outstanding: 'Outstanding deposit to pay',
        },
      },
      tax_experts: {
        title: 'Your Tytle tax experts',
        tax_expert: 'Tytle tax partner',
        empty: 'No tax experts at the moment!',
        error: 'There was an error trying to fetch your tax experts!',
        tytle_partner: 'Tytle tax partner',
        advisors: 'Team',
        retry: 'Retry',
      },
      tax_experts_partner: {
        title: 'Tytle experts working with this client',
        tax_expert: 'Tytle tax partner',
        tytle_tax_partner_at: 'Tytle tax expert at {{name}}',
      },
    },
    messages: {
      no_chats:
        'Once the client has reviewed and accepted your proposal, you can use this chat channel for communication.',
    },
    proposals: {
      proposal: 'proposal',
      received: 'received {{time}}',
      partner_received: 'sent {{time}}',
      proposal_from: 'Proposal from',
      expand_button: 'Click to expand',
      value: '{{value}} per hour',
      details: {
        details: 'Details',
        scope: 'Scope',
        assumptions: 'Assumptions',
        extra_info: 'Extra Information',
        received_from: 'Received {{date}} from {{author}} at ',
        button_accept: 'Accept proposal',
        button_reject: 'Reject proposal',
        terms: {
          title: 'Terms and Conditions',
          warn: 'By accepting the proposal you accept the terms and',
          warn_2: 'conditions of',
          terms_by: '{{from}} terms & conditions',
        },
        lightboxes: {
          scope: {
            title: 'Scope',
            description: `"Scope" describes what the expert is going to do. Here you will find a description of the work that was agreed upon
            and, if possible, also of the results.`,
          },
          assumptions: {
            title: 'Assumptions',
            description: `"Assumptions" is about the expectations that you and the expert have. For example, the assumption "maximum of 2
            meetings" means that 2 meetings are included. In case you request more meetings, the expert may send you a fee
            proposal for an extra meeting. Only accept a proposal if the assumptions are in line with your expectations.`,
          },
          details: {
            title: 'Details',
            description: `"Details" are the data (label, time/date, name client, name advisor) used to identify your case.`,
          },
          extra_information: {
            title: 'Extra information',
            description: `"Extra information" refers to any additional information that is relevant for your case, or for the way it will be handled.`,
          },
          terms_and_conditions: {
            title: 'Terms and conditions',
            description: `"Terms and conditions" are the legal framework of the assignment. Always read them carefully as they (may)
            determine many practical issues, like payment terms, liability and how disputes are handled`,
          },
        },
        empty_state:
          "we're currently working on an updated proposal for you, hang tight",
        in_review: 'Your proposal is in review',
        in_rereview:
          'Unfortunately, proposal {{proposalId}} has been rejected by the client. The client stated the following reason:\n\n{{clientComments}}',
        rejected: `You've rejected the proposal. Both your advisor and the Tytle support team have been informed and will be reaching out to you shortly.`,
        go_payment: 'Go to payment',
        accept_modal: {
          title:
            'A deposit of {{amount}} is due, to accept this proposal and start working with your Tytle experts on your advice topic you will have to pay the deposit. This deposit will be deducted of the total amount when you have been helped with your tax advice needs. In which currency do you prefer to pay?',
        },
        extra_text_paid_fixed:
          'The work cannot exceed the total of {{value}} (incl. VAT) without notice. If the estimation of work required has increased, {{partnerName}} is obliged to inform you in a timely manner about the estimated cost increase.',
        extra_text_paid_hourly:
          'The work cannot exceed {{value}} (incl. VAT) without notice. If the estimation of work required has increased, {{partnerName}} is obliged to inform you in a timely manner about the estimated cost increase.',
        submit_proposal: 'Submit payment',
        request_payment: 'Request final payment',
        request_deposit_payment: 'Request deposit payment',
        payment_requested: 'Client has been sent a message to pay the deposit',
        payment_final_requested:
          'Client has been sent a message to pay the final amount',
        invoice_uploaded: 'The client has been notified about the new invoice',
        payment_due:
          'A {{type}} payment of {{amount}} is required before {{date}}.',
        stripe_conneted_warning:
          'Connect your firm to our Stripe page before\nrequesting payments, go to: app.tytle.io/stripeconnect.',
      },
      empty_state: "You don't have proposals yet",
      statuses: {
        rereview: 'rejected',
        redraft: 'rejected',
        draft: 'draft',
        rejected: 'rejected',

        proposed: 'new',
        accepted: 'accepted',
        requested: 'requested by Tytle',

        pending: 'payment due',
        payment_due: 'payment due',

        review: 'review',

        archived: 'archived',
        in_rereview: 'in rereview',
        closed: 'closed',
        paid_closed: 'paid and closed',

        partner: {
          review: 'in review by Tytle',
          rereview: 'in review by Tytle',
          proposed: 'submitted',
        },
      },
      modal: {
        title:
          "Could you share why the proposal didn't meet your expectations? Your feedback will be forwarded to our advisor for review. We'll follow up with you shortly.",
        error_message: 'You need to leave a comment',
        input_placeholder: 'Enter here',
      },
      payment: {
        title:
          'A deposit of {{value}} is required\n({{percent}}% of {{amount}})',
        subtitle:
          'After payment of the deposit, {{partner}} will start the process.',
        final_payment: {
          title: 'Amount due: {{amount}}',
          subtitle:
            'Your deposit of {{amount}} has been credited towards the total amount of {{totalAmount}}.',
          button: 'Pay {{amount}}',
        },
      },
    },
    create_proposal: {
      empty_state:
        'Please click on the button below to create the first proposal.\nWhen you submit your proposal for {{name}}, Tytle will review\nyour proposal before forwarding it to the client.',
      button_create_proposal: 'Create proposal',
      button_create_proposal_alert:
        'Please finish your draft proposal before creating new proposals.',
      proposal_type: 'Proposal type',
      scope_of_work: 'Scope of work',
      err_scope_of_work: 'You must fill the scope of work',
      assumptions: 'Assumptions',
      add_assumptions: 'Add assumption',
      fee_amount: 'Fee amount',
      amount: 'Amount',
      hours_estimated: 'Hours estimated',
      hourly_rate: 'Hourly rate',
      you_receive: 'Your fee {{amount}}',
      tytle_receives: "Tytle's fee {{amount}}",
      final_price: 'Final price client (incl. VAT): {{amount}}',
      deposit: 'Deposit',
      percentage: 'Percentage',
      err_percentage: 'the value must be between 1 and 100',
      standard_invoice: 'Use standard invoice',
      own_invoice: 'Upload own invoice',
      text_invoice:
        'Do you want Tytle to generate a standard invoice and send it to the client on behalf of {{partner_name}} (it will state that it was issued by Tytle on behalf of), or do you want to us to send your own proprietary invoice to the client?',
      welcome_message: 'Welcome message',
      additional_information: 'Additional information for client',
      submit_proposal: 'Submit proposal',
      save_draft: 'Save draft',
      err_amount: 'the value must be greater than zero',
      lightboxes: {
        proposal_type: {
          title: 'Proposal type',
          description: `Free consult: A complimentary 30-minute session to give initial tax advice.\n
          Free introduction call: A quick meet-up to discuss services and fees with the client.\n
          Fixed fee: Secure your fee. Any changes must be communicated in advance to both Tytle and the client.\n
          Hourly rate: Specify a maximum number of hours and your hourly rate. Tytle can make adjustments if fewer hours were required.`,
        },
        scope: {
          title: 'Scope of work',
          description: `Defines the expert's responsibilities. Detail the agreed-upon work and, if feasible, the expected outcomes.`,
        },
        assumptions: {
          title: 'Assumptions',
          description: `Covers mutual expectations. For instance, "maximum of 2 meetings" indicates that two meetings are included. Additional meetings beyond this can be accommodated with a supplementary fee proposal.`,
        },
        additional_information: {
          title: 'Additional information for client',
          description: `Any extra details pertinent to the case or its management.`,
        },
        welcome_message: {
          title: 'Welcome message',
          description: `Personalize a welcome message for the client, delivered via chat upon accepting your proposal. If left blank, a standard greeting will be used.`,
        },
      },
    },
    uploads: {
      upload_button: 'New upload',
      uploaded_by: 'Uploaded by',
      uploaded_time: 'uploaded {{time}}',
      consent_pop_up: {
        title: 'Are you sure?',
        subtitle: 'This file will be permanently deleted',
        yes: 'Yes',
        no: 'No',
      },
      upload_modal: {
        title: 'Adding a new file',
        file_title: 'File title',
        button_add: 'Add file',
        button_cancel: 'Cancel',
        input_error: 'Input a valid title, min 3 characters',
        file_error: 'Input a file',
      },
      empty_state:
        "You don't have files, please click on the button below to upload a new one.",
    },
    label: 'label',
    countries: 'countries',
    status: 'status',
    statuses: {
      info_required: 'advice request submitted',
      review: 'expert proposal for review',
      default: 'default',
    },
    tooltips: {
      proposals:
        'Complete your request in the "overview" section\nbefore receiving proposals from Tytle advisors.',
      messages:
        'Complete your request in the "overview" section\nbefore chatting with your Tytle advisors.',
      uploads:
        'Complete your request in the "overview" section\nbefore uploading documents.',
      payments:
        'Complete your request in the "overview" section\nbefore viewing your payments.',
    },
  },
  file_uploader: {
    upload_alert:
      'Please upload your documents here by dragging a file or click on "Upload a file"',
    upload_button: 'Upload files',
    single_upload_button: 'Upload a file',
    drop: 'Or drop files here to upload',
    single_drop:
      'If you want to upload multiple files please archive or zip them first',
    success: 'Upload successful',
    error: 'Error uploading file',
    uploading: 'Uploading document ...',
    max_file_size: 'Maximum file size: 25MB',
    over_limit: 'This file is over 25MB',
    unsuported_type: 'This file type is not suported',
    default_error: 'Error during file upload',
    invalid_keys: 'This file contains invalid keys',
  },
  tax_allocation_start: {
    title: 'Your worldwide income and assets for the year {{year}}',
    description:
      'We’re now going to ask you some questions about your income and capital. When there are international aspects to it, we sometimes have to go a bit more in-depth. But don’t be afraid, you probably don’t need to dig up any paperwork.\n\nYour progress will be saved after each question, so feel free to walk away at any moment, and come back after lunch. Or the next day.\n\nLet’s get started.',
    cta_modal_text: '',
    submit: 'Start',
  },
  tax_allocation_part_of_year: {
    title: 'What applied to you for all, or part, of {{year}}?',
    cta_modal_text: '',
    submit: 'Continue',
    questions: {
      had_job: 'I had a job (either employed or self-employed)',
      was_unemployed: 'I was unemployed',
      was_student: 'I was a student',
      intern_trainee_apprentice:
        'I was an intern, trainee, or business apprentice',
      had_company: 'I owned a (holding) company',
      answers: {
        yes: 'Yes',
        no: 'No',
      },
    },
  },
  tax_allocation_company_holdings: {
    title:
      'Please note that all questions that will be asked in this questionnaire relate to you as an individual.',
    description:
      "This means that income flowing to your (holding) company should not be taken into account here. Please check with your (holding) company's tax advisor if you have doubts. (Or tell us that you need one, if that's the case.)\nHowever, income flowing from your (holding) company or to you personally, should be taken into account.\n\nPlease click continue to move to the next question.",
    cta_modal_text: 'Examples of what is relevant',
    submit: 'Continue',
  },
  tax_allocation_tax_transparent: {
    title: 'Did you own a so-called tax transparent company in {{year}}?',
    description:
      'This is a company that is not a separate legal entity but, instead, has full financial overlap with its owner. For many self-employed people, like freelancers or sole proprietors, this is the case.',
    cta_modal_text: '',
    answers: {
      yes: 'Yes',
      no: 'No',
    },
    submit: 'Continue',
  },
  tax_allocation_menu: {
    title:
      'Did you have any of the following types of capital gains (or losses) in {{year}}?',
    description: '',
    cta_modal_text: '',
    menus: {
      shares: {
        title: 'Sale of shares',
        description: 'or similar rights in a company',
      },
      sold_precious: {
        title: 'Sale of precious metals',
        description: 'gold, silver, platinum, or similar',
      },
      sold_real_estate: {
        title: 'Sale of real estate',
        description: 'your house, houseboat, or other immovable property',
      },
      sold_movable: {
        title: 'Sale of objects',
        description: 'jewelry, art, vehicle, or other valuable items',
      },
      sold_crypto: {
        title: 'Sale of crypto',
        description: 'bitcoin, altcoins, stablecoins, NFTs, or similar',
      },
      other_capital_gains: {
        title: 'Sale of other capital gains',
        description: 'any gains or losses not listed here',
      },
      none: {
        title: "I didn't have any capital gains",
        description: '',
      },
    },
  },
  tax_allocation_types_of_income: {
    title: 'Did you have any of the following types of income in {{year}}?',
    description: '',
    cta_modal_text: '',
    menus: {
      rent: {
        title: 'Rent',
        description: 'any type of rental income from your property',
      },
      severance: {
        title: 'Severance',
        description: 'income after your job was terminated',
      },
      interest: {
        title: 'Interest',
        description: 'on your bank account, bonds, loans, or similar',
      },
      dividends: {
        title: 'Dividends',
        description: 'on shares or similar rights',
      },
      royalties: {
        title: 'Royalties',
        description: 'for use of a patent, copyright, trademark or similar',
      },
      pension_or_annuity: {
        title: 'Pension or annuity',
        description: 'or similar',
      },
      alimony_support: {
        title: 'Alimony or child support',
        description: 'or similar',
      },
      gambling_winnings: {
        title: 'Gambling winnings',
        description: 'from a lottery, horse betting, casinos, or similar',
      },
      welfare_benefits: {
        title: 'Welfare or social benefits',
        description: 'or similar',
      },
      gifts: {
        title: 'Gifts',
        description: 'any type of gift: cash, an object or other property',
      },
      inheritance: {
        title: 'Inheritance',
        description: '',
      },
      other_income: {
        title: 'Other income',
        description: 'anything not listed here',
      },
      none: {
        title: "I didn't have any of the income above",
        description: '',
      },
    },
  },
  additional_complexities: {
    non_specific: {
      title: 'Select all complexities that may apply to you',
      description:
        'Below are some general complexities that are considered relevant in many countries. Please select all that applied to you in {{fiscalYear}}. This helps us to provide you with the right fee estimate. Good to know: complexities do not always lead to a higher fee, so try to be as complete as possible.',
      lightbox: '',
    },
    country_specific: {
      title:
        'Select all complexities that may apply to you. These are specific to {{country}}',
      description:
        'Below are some general complexities that have specific relevance in {{country}}. Please select all that applied to you in {{fiscalYear}}. This helps us to provide you with the right fee estimate. Good to know: complexities do not always lead to a higher fee, so try to be as complete as possible.',
      lightbox: '',
    },
    extra_complexities: {
      title: 'Did we forget a complexity? Please let us know',
      description:
        'Please tell us about other complexities that we didn’t ask about. Don’t be afraid to mention irrelevant complexities. We’ll figure out and let you know.',
      lightbox: '',
    },
    other_services_needed: {
      title: 'Do you need extra services in one or more countries?',
      description:
        "Please select all additional services you may need. We'll go over them with you during our call before starting your tax filings.",
      lightbox: '',
      answers: {
        accounting: {
          text: 'Accounting',
          description: 'bookkeeping, VAT administration, financial reports',
        },
        personal_tax_planning: {
          text: 'Personal tax planning',
          description: 'optimizing your personal taxes',
        },
        status_apply: {
          text: 'Apply for a status',
          description: 'beneficial tax rules for expats or similar',
        },
        immigration: {
          text: 'Immigration services',
          description: 'obtaining a visa or work permit',
        },
        business_tax_planning: {
          text: 'Business tax planning',
          description: 'optimizing the tax setup of your business',
        },
        other: {
          text: 'Other services',
          description: 'services not listed here that you believe you may need',
        },
      },
    },
    other_services_needed_free_input: {
      title:
        'Please tell us a bit more about the services you’re interested in, if any',
      description:
        'For example, the type of accounting or bookkeeping you need, the country for which you seek assistance, the type of business that is involved, or the kind of tax planning that you’re looking for.',
      lightbox: '',
    },
  },
  countries: {
    AA: 'Aanazam',
    QQ: 'the Quebaq Iudans',
    ZZ: 'Zstani Ziaraz',
    AW: 'Aruba',
    AF: 'Afghanistan',
    AO: 'Angola',
    AI: 'Anguilla',
    AX: 'the Åland Islands',
    AL: 'Albania',
    AD: 'Andorra',
    AE: 'the United Arab Emirates',
    AR: 'Argentina',
    AM: 'Armenia',
    AS: 'American Samoa',
    AQ: 'Antarctica',
    TF: 'the French Southern Territories',
    AG: 'Antigua and Barbuda',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BI: 'Burundi',
    BE: 'Belgium',
    BJ: 'Benin',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BF: 'Burkina Faso',
    BD: 'Bangladesh',
    BG: 'Bulgaria',
    BH: 'Bahrain',
    BS: 'the Bahamas',
    BA: 'Bosnia and Herzegovina',
    BL: 'Saint Barthélemy',
    BY: 'Belarus',
    BZ: 'Belize',
    BM: 'Bermuda',
    BO: 'Bolivia',
    BR: 'Brazil',
    BB: 'Barbados',
    BN: 'Brunei Darussalam',
    BT: 'Bhutan',
    BV: 'Bouvet Island',
    BW: 'Botswana',
    CF: 'the Central African Republic',
    CA: 'Canada',
    CC: 'the Cocos (Keeling) Islands',
    CH: 'Switzerland',
    CL: 'Chile',
    CN: 'China',
    CI: 'Ivory Coast',
    CM: 'Cameroon',
    CD: 'the Democratic Republic of the Congo',
    CG: 'Congo',
    CK: 'the Cook Islands',
    CO: 'Colombia',
    KM: 'the Comoros',
    CV: 'Cabo Verde',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CW: 'Curaçao',
    CX: 'Christmas Island',
    KY: 'the Cayman Islands',
    CY: 'Cyprus',
    CZ: 'Czechia',
    DE: 'Germany',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DK: 'Denmark',
    DO: 'Dominican Republic',
    DZ: 'Algeria',
    EC: 'Ecuador',
    EG: 'Egypt',
    ER: 'Eritrea',
    EH: 'Western Sahara',
    ES: 'Spain',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'the Falkland Islands',
    FR: 'France',
    FO: 'the Faroe Islands',
    FM: 'Micronesia',
    GA: 'Gabon',
    GB: 'the United Kingdom',
    GE: 'Georgia',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GM: 'Gambia',
    GW: 'Guinea-Bissau',
    GQ: 'Equatorial Guinea',
    GR: 'Greece',
    GD: 'Grenada',
    GL: 'Greenland',
    GT: 'Guatemala',
    GF: 'French Guiana',
    GU: 'Guam',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HM: 'Heard Island and the McDonald Islands',
    HN: 'Honduras',
    HR: 'Croatia',
    HT: 'Haiti',
    HU: 'Hungary',
    ID: 'Indonesia',
    IM: 'the Isle of Man',
    IN: 'India',
    IO: 'British Indian Ocean Territory',
    IE: 'Ireland',
    IR: 'Iran',
    IQ: 'Iraq',
    IS: 'Iceland',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JE: 'Jersey',
    JO: 'Jordan',
    JP: 'Japan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KI: 'Kiribati',
    KN: 'Saint Kitts and Nevis',
    KR: 'South Korea',
    XK: 'Kosovo',
    KW: 'Kuwait',
    LA: 'Laos',
    LB: 'Lebanon',
    LR: 'Liberia',
    LY: 'Libya',
    LC: 'Saint Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LS: 'Lesotho',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    MO: 'Macao',
    MOP: 'Macanese Pataca',
    MF: 'Saint Martin (the French part)',
    MA: 'Morocco',
    MC: 'Monaco',
    MD: 'Moldova',
    MG: 'Madagascar',
    MV: 'the Maldives',
    MX: 'Mexico',
    MH: 'the Marshall Islands',
    MK: 'North Macedonia',
    ML: 'Mali',
    MT: 'Malta',
    MM: 'Myanmar',
    ME: 'Montenegro',
    MN: 'Mongolia',
    MP: 'the Northern Mariana Islands',
    MZ: 'Mozambique',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MQ: 'Martinique',
    MU: 'Mauritius',
    MW: 'Malawi',
    MY: 'Malaysia',
    YT: 'Mayotte',
    NA: 'Namibia',
    NC: 'New Caledonia',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NU: 'Niue',
    NL: 'the Netherlands',
    NO: 'Norway',
    NP: 'Nepal',
    NR: 'Nauru',
    NZ: 'New Zealand',
    OM: 'Oman',
    PK: 'Pakistan',
    PA: 'Panama',
    PN: 'Pitcairn',
    PE: 'Peru',
    PH: 'the Philippines',
    PW: 'Palau',
    PG: 'Papua New Guinea',
    PL: 'Poland',
    PR: 'Puerto Rico',
    KP: 'North Korea',
    PT: 'Portugal',
    PY: 'Paraguay',
    PS: 'Palestine',
    PF: 'French Polynesia',
    QA: 'Qatar',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    SA: 'Saudi Arabia',
    SD: 'Sudan',
    SN: 'Senegal',
    SG: 'Singapore',
    GS: 'South Georgia and the South Sandwich Islands',
    SH: 'Saint Helena',
    SJ: 'Svalbard and Jan Mayen',
    SB: 'the Solomon Islands',
    SL: 'Sierra Leone',
    SV: 'El Salvador',
    SM: 'San Marino',
    SO: 'Somalia',
    PM: 'Saint Pierre and Miquelon',
    RS: 'Serbia',
    SS: 'South Sudan',
    ST: 'Sao Tome and Principe',
    SR: 'Suriname',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SE: 'Sweden',
    SZ: 'Eswatini',
    SX: 'Sint Maarten (the Dutch part)',
    SC: 'the Seychelles',
    SY: 'Syria',
    TC: 'the Turks and Caicos Islands',
    TD: 'Chad',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TK: 'Tokelau',
    TM: 'Turkmenistan',
    TL: 'Timor-Leste',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    UG: 'Uganda',
    UA: 'Ukraine',
    UM: 'the United States Minor Outlying Islands',
    UY: 'Uruguay',
    US: 'the United States',
    UZ: 'Uzbekistan',
    VA: 'Vatican',
    VC: 'Saint Vincent and the Grenadines',
    VE: 'Venezuela',
    VG: 'the British Virgin Islands',
    VI: 'the U.S. Virgin Islands',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis and Futuna',
    WS: 'Samoa',
    YE: 'Yemen',
    ZA: 'South Africa',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
  buttons: {
    continue: 'Continue',
    finish: 'Submit',
  },
  template_errors: {
    integer: 'Value has to be between {{min}} and {{max}}',
    dates: 'Date has to be between {{min}} and {{max}}',
    invalidDate: 'Invalid date',
    freeText: 'Text should be between {{min}} and {{max}} characters',
    currency: 'Value has to be between {{min}} and {{max}}',
    percentage: 'Value not between {{min}} and {{max}} or invalid',
    chooseCurrency: 'Please select a currency',
    chooseCountry: 'Please select a country',
    phoneNumber: 'Please enter a valid phone number',
    email: 'Please enter a valid email address',
  },
  app: {
    personal_template: {
      birthdate: 'Date of birth',
    },
    genders: {
      male: 'Male',
      female: 'Female',
      other: 'Other',
    },
    home: {
      welcome: 'Welcome {{name}}!',
      reminders: 'Reminders',
      tax_filings: 'Tax filings in progress',
      ongoing: 'Ongoing advice topic',
      tip: 'Tytle tip #{{number}}',
      learn_more: 'learn more...',
    },
    filings: {
      title: 'Let’s get started!',
      description: '',
      box_title: 'Which tax year would you like to start with?',
      button: 'Continue with',
      deadlines: {
        tomorrow: 'Tomorrow',
        today: 'Today',
        yesterday: 'Yesterday',
        days_ago: '{{days}} days ago',
        in_days: 'in {{days}} days',
      },
    },
    filings_answersed: {
      title_inbox: 'Smart Inbox',
      title_deadlines: 'Deadlines',
      title_deadlines_empty: 'Nothing to see here yet',
      title_filings: 'Open filings',
      button: 'add a new year',
      tax_returns: 'Tax filings',
    },
    consultation: {
      login: {
        title: 'Please create an account to schedule a free consulation',
        subtitle:
          "Before the consultation, we'd like to ask you a few questions. Click here to register and get started.",
        title_box: 'Hello! Login or register',
      },
      schedule: {
        title: 'What to expect?',
        description: {
          minutes: 'A consultation takes 10 to 15 minutes',
          analyze:
            'We’ll analyze your international tax situation and suggest next steps (if any)',
          determine: 'We’ll determine which experts should be involved',
          no_chage: 'No commitment or payment needed',
        },
        notes: {
          title: 'Please note',
          customized_advice:
            'We can’t give full, customized advice during this intro call',
          steps:
            'If next steps are needed, we’ll give an indication of costs (if any)',
          quote:
            'Sometimes we can give you a precise quote, sometimes a quote range',
          calls: 'Calls are completely confidential',
        },
        button: 'Schedule a call',
      },
      success: 'Thank you for scheduling the\nconsultation! Talk to you soon',
      email: {
        title:
          'To schedule your free consultation,\nplease verify your account by clicking\non the button in the email we sent to\nyou',
        subtitle:
          'You can refresh this page when you have verified your email addres',
      },
    },
    login: {
      title: 'Log in',
      emailPlaceholder: 'E-mail',
      passwordPlaceholder: 'Password',
      forgotPassword: 'Forgot password',
      loginButton: 'Login',
      quicklyAccess: 'or access quickly',
      googleSignIn: 'Sign-in with Google',
      noAccount: "Don't have an account?",
      verifyEmail:
        'Verification email sent to {{email}}. Please check your inbox and click on the verify email button\n\nDon\'t see an email? Check your spam folder and mark as "not spam"',
      error:
        'Login failed, try again in a few minutes or contact us if it persists',
    },
    register: {
      title: 'Register',
      emailPlaceholder: 'E-mail',
      firstNamePlaceholder: 'First name',
      lastNamePlaceholder: 'Last name',
      passwordPlaceholder: 'Password',
      signUpButton: 'Sign up',
      haveAnAccount: 'Already have an account? Log in',
      quicklyAccess: 'or access quickly',
      googleSignIn: 'Sign-in with Google',
      alreadyExists: 'Account already exist, please login',
      error:
        'Register failed, try again in a few minutes or contact us if it persists',
    },
    forgotPassword: {
      title: 'Need a new password?',
      emailSent: 'A reset password link was sent to',
      emailPlaceholder: 'E-mail',
      button: 'Get a new password',
      haveAnAccount: 'Already have an account? Log in',
      quicklyAccess: 'or access quickly',
    },
    resetPassword: {
      toast: {
        invalidToken: 'Sorry, Invalid token',
        invalidAccessToken: 'Invalid access token or type',
        passwordChanged: 'Password Changed',
        error: 'Sorry Error occured',
      },
      title: 'Reset your password',
      passwordPlaceholder: 'Password',
      confirmButton: 'Confirm',
      haveAnAccount: 'Already have an account? Log in',
      quicklyAccess: 'or access quickly',
      googleSignIn: 'Sign-in with Google',
    },
    python: {
      item_body: 'Labeled as “{{label}}”',
      label_fallback: 'Income item',
      confirm: {
        title: 'Are you sure you want to continue?',
        subtitle:
          'You mentioned that you had income for this section but you did not add any item. If you made a mistake by indicating that you had this type of income incorrectly, you can leave it blank. Click "yes" to go to the next section or click "no" to add an income item to this section.',
      },
      employment: {
        title: 'All items related to (self-)employment for {{fiscalYear}}',
        subtitle:
          'Please add all types of (self-)employment that you held for the tax year of {{fiscalYear}}, whether it was short-term, on a flexible contract, on a permanent contract, overseas, as a student, et cetera.',
        addButton: 'Add (self-)employment item',
        continueButton: 'Continue',
        item: {
          not_found: '(Self-)employment',
          employed: 'Employment',
          self_employed: 'Self-employment',
        },
      },
      social_contributions: {
        title: 'Social contribution payments in {{fiscalYear}}',
        itemTitle: 'Social contribution payment information',
        subtitle:
          'You have indicated that you were employed and / or self-employment in {{fiscalYear}}. Please enter the questionnaire by clicking on "add social contribution information".',
        addButton: 'Add social contributions information',
        employment_not_done:
          'Please add at least one employment or self-employment item before you add social contribution payments information',
        continueButton: 'Continue',
      },
      students: {
        title: 'All items related to income as a student for {{fiscalYear}}',
        subtitle:
          'Please add all sources of student income that you received during the tax year of {{fiscalYear}}. This includes, but is not limited to, scholarships, stipends, gifts, inheritances, and any other income not related to employment. If you received income from internships, traineeships, or regular employment, please report it in the appropriate section for (self-)employment.',
        addButton: 'Add student income item',
        continueButton: 'Continue',
        item: {
          not_found: 'Student income',
          grant_scholarship: 'Grant, scholarship, or stipend',
          gift: 'Gift',
          inheritance: 'Inheritance',
          employment: 'Employment income',
          traineeship: 'Traineeship fee',
          internship: 'Internship fee',
          other: 'Other student income',
        },
      },
      welfare: {
        title:
          'All items related to social benefits or welfare income in {{fiscalYear}}',
        subtitle:
          'You indicated that you were unemployed during {{fiscalYear}} or received welfare. Please add all your social benefits or income from welfare in this section. This may include unemployment benefits, disability benefits, parental leave, sick leave, government child support, and any support received for medical treatment or injuries related to employment.',
        addButton: 'Add welfare income item',
        continueButton: 'Continue',
        item: {
          not_found: 'Welfare',
          unemployment: 'Unemployment benefits',
          disability: 'Disability benefits',
          support_medical_treatment: 'Medical treatment support',
          support_injury_employment: 'Employment injury support',
          parental_leave: 'Parental leave benefits',
          sick_leave: 'Sick leave benefits',
          governmental_child_support: 'Governmental child support',
          other: 'Other welfare',
        },
      },
      capital_gains: {
        title:
          'All items related to capital gains (or losses) in {{fiscalYear}}',
        subtitle:
          'Please add all your capital gains or losses in this section. Examples sale of cryptocurrencies, sale of shares, or sale of real estate.',
        addButton: 'Add capital gain / loss item',
        continueButton: 'Continue',
        item: {
          not_found: 'Capital gain / loss',
          crypto: 'Crypto',
          commodities: 'Commodities',
          metals: 'Precious metals',
          real_estate: 'Real estate',
          shares: 'Shares or similar',
          objects: 'Movable objects',
          other: 'Other gain / loss',
        },
      },
      dividends: {
        title: 'All items related to dividends in {{fiscalYear}}',
        subtitle:
          'Please add all items related to dividend income earned in {{fiscalYear}} in this section. This includes income from shares or similar corporate rights, income from jouissance rights, and income from mining shares.',
        addButton: 'Add dividend item',
        continueButton: 'Continue',
        item: {
          not_found: 'Dividend',
          regular_shares: 'Shares dividend',
          jouissance: 'Jouissance rights dividend',
          mining_shares: 'Mining shares dividend',
          other: 'Other dividend',
        },
      },
      interest: {
        title: 'All items related to interest income in {{fiscalYear}}',
        subtitle:
          'Please add all items related to interest income in {{fiscalYear}} in this section. Examples include interest earned on your bank account savings, interest on a bond, or interest on a loan to a business or other person.',
        addButton: 'Add interest item',
        continueButton: 'Continue',
        item: {
          not_found: 'Interest',
          bonds: 'Interest from bonds',
          gov_bonds: 'Interest from government bonds',
          bank: 'Interest from a bank',
          other: 'Other interest',
        },
      },
      pensions_annuities: {
        title: 'All items related to pensions or annuities in {{fiscalYear}}',
        subtitle:
          'Please add all items related to pension and annuities income received in {{fiscalYear}} in this section. Examples may include payments from a retirement plan, an annuity contract, or a government-provided pension.',
        addButton: 'Add pension / annuity item',
        continueButton: 'Continue',
        item: {
          not_found: 'Pension / annuity',
          pension: 'Pension',
          annuity: 'Annuity',
          similar: 'Other pension / annuity',
        },
      },
      misc_income: {
        title: 'All other income items in {{fiscalYear}}',
        subtitle:
          'You indicated that you received forms of miscellaneous income in {{fiscalYear}}. Please list all additional sources of income in this section, including but not limited to rental income, gifts, inheritances, gambling winnings, alimony, and severance payments.',
        addButton: 'Add other income item',
        continueButton: 'Continue',
        item: {
          not_found: 'Miscellaneous',
          rent: 'Rental income',
          alimony: 'Alimony',
          gifts: 'Gifts',
          severance: 'Severance payments',
          gambling: 'Gambling winnings',
          royalties: 'Royalties',
          inheritance: 'Inheritance',
          other: 'Other miscellaneous income',
        },
      },
      assets: {
        title: 'All assets in {{fiscalYear}}',
        subtitle:
          'Please ensure that all assets you possessed during the tax year {{fiscalYear}}, including but not limited to bank accounts, real estate, stocks, digital assets, and cash, are accurately included in your list of assets.',
        addButton: 'add asset',
        continueButton: 'Continue',
        item: {
          not_found: 'Asset',
          cash: 'Cash',
          bank_account: 'Bank account',
          real_estate: 'Real estate',
          shares_exchange: 'Shares on an exchange',
          shares_real_estate: 'Shares in real estate company',
          shares_regular: 'Shares in a private company',
          receivables: 'Receivable',
          value_art: 'High value art',
          metals: 'Precious metals',
          other: 'Other assets',
        },
      },
      hardcoded_questions: {
        first: {
          headerText:
            'You indicated that you had a <span data-tooltip="Either self-employed or employed at a company. You are self-employed if you work as a contractor, for yourself, or similar.">job</span> in {{fiscalYear}}. What best describes your work situation in {{fiscalYear}}?',
          bodyText:
            "Did you have more than one job? Just pick one to start with. We'll go through the other job once we've finished the first.",
          lightBoxLabel: '',
          answers: {
            employed: 'I was employed',
            self_employed: 'I was self-employed',
            worked_other: "I worked, but I wasn't employed or self-employed",
            skip_category: 'I was unemployed, let’s skip these questions',
          },
        },
        new_item: {
          headerText: "What best the describes the item you'd like to add?",
          lightBoxLabel: '',
          answers: {
            employed: 'I was employed',
            self_employed: 'I was self-employed',
            worked_other: "I worked, but I wasn't employed or self-employed",
          },
        },
        welfare: {
          headerText:
            'You indicated that you received welfare / benefits / financial support or were unemployed during {{fiscalYear}}. Please select one item to start with.',
          bodyText:
            'Did you receive more than one type of welfare / benefits / financial support? Just pick one to start with. We’ll go through the other type of welfare / benefits / financial support once we’ve finished the first. ',
          answers: {
            unemployment_benefits: 'Unemployment benefits',
            disability_benefits: 'Disability benefits',
            medical_support: 'Support for medical treatment',
            injury_support: 'Support for injury in the course of employment',
            parental_leave: 'Parental leave',
            sick_leave: 'Sick leave',
            child_support: 'Government child support',
            other: 'Other type of welfare / benefits / financial support',
            skip: 'No, I didn’t receive welfare. Let’s skip this question.',
          },
        },
        students: {
          headerText:
            'You indicated that you were a student in {{fiscalYear}}. While being a student, did you have any of the following types of income in {{fiscalYear}}?',
          bodyText:
            'Did you have more than one type of income? Just pick one to start with. We’ll go through the other types of income once we’ve finished the first.',
          lightBoxLabel: 'Examples of income as a student',
          lightBoxText: `### Examples of income as a student

Any income while you were a student. It doesn't matter whether this income was subsidized (like a grant, allowance or scholarship) or not (like a gift from friends or family or an inheritance).`,
          answers: {
            grants:
              'grants, scholarships, awards, allowances, stipends, prizes and / or rewards',
            gifts: 'gifts',
            inheritance: 'inheritance',
            traineeship: 'traineeship',
            internship: 'internship',
            employment:
              'I had employment that was not a traineeship or internship',
            other: 'other payments, remuneration and / or remittances',
            skip: 'I had none of the above, let’s skip this section',
          },
        },
        interest: {
          headerText:
            'You indicated that you received <span data-tooltip="“Income” means that you received an interest payment.">income</span> from interest in {{fiscalYear}}. Please select one item to start with.',
          bodyText:
            'Did you receive more than one type of interest? Just pick one to start with. We’ll go through the other type of interest once we’ve finished the first. ',
          lightBoxLabel: 'What are (government) bonds and debentures?',
          lightBoxText: `### What are (government) bonds and debentures?
Income from bonds or debentures is money earned by investing in debt securities issued by companies or organizations. These securities represent a loan made by the investor to the issuer, and the issuer agrees to pay interest to the investor. Income from government bonds or securities is money earned by investing in debt securities issued by a government. These securities represent a loan made by the investor to the government and the government agrees to pay interest to the investor. The interest rate on government bonds is often lower than that of other bonds, but the investment is considered to be less risky.`,
          answers: {
            bonds: 'Income from non-government bonds or debentures',
            gov_bonds: 'Income from government bonds or securities',
            bank: 'Interest from funds in a bank account',
            other: 'Interest from any other debt-claim',
            none: 'No, I didn’t have such income. Let’s skip this question.',
          },
        },
        dividends: {
          headerText:
            'You indicated that you received income from dividends in  {{fiscalYear}}. Please select one type of dividend to start with.',
          bodyText:
            'Did you receive more than one type of dividend? Just pick one to start with. We’ll go through the other type of dividend once we’ve finished the first.',
          lightBoxLabel: 'What are jouissance rights and mining shares?',
          lightBoxText:
            'Jouissance rights is a broad term for rights that are quite similar to property rights, but not exactly the same. Often, the owner of such rights has to accept certain limitations. For instance, they cannot sell, transfer or mortgage the property. With regard to shares, voting or administration rights can be restricted. The legal terms and constructions related to jouissance rights differ per country. If you don’t hold regular shares, but something similar to it, you probably own jouissance rights.\n\nMining shares refer to ownership in a mining company that operates to extract minerals, metals, and other resources from the earth. The owner of such shares (often) receives a portion of the company’s profit in the form of dividends.',
          answers: {
            regular_shares: 'Income from shares or similar corporate rights',
            jouissance: 'Income from jouissance rights',
            mining_shares: 'Income from mining shares',
            other: 'Other',
            none: 'No, I didn’t have such income. Let’s skip this question.',
          },
        },
        pensions_annuities: {
          headerText:
            'You indicated that you received a pension or annuities in {{fiscalYear}}. Please select one item to start with.',
          bodyText:
            'Did you receive more than one type of pension / annuities? Just pick one to start with. We’ll go through the other type of pension / annuities once we’ve finished the first.',
          answers: {
            pension: 'Pension',
            annuity: 'Annuity',
            similar: 'Something similar to a pension or an annuity.',
            none: 'No, I didn’t have such income. Let’s skip this question.',
          },
        },
        misc_income: {
          headerText:
            'You indicated that you had the following types of income in {{fiscalYear}}. Please select one item to start with.',
          bodyText:
            'Did you receive more than one type of income? Just pick one to start with. We’ll go through the other type of income once we’ve finished the first.',
          answers: {
            rent: 'Rent',
            alimony: 'Alimony or non-government child support',
            gifts: 'Gifts',
            severance: 'Severance payments',
            royalties: 'Royalties',
            inheritance: 'Inheritance',
            gambling: 'Gambling winnings',
            other: 'Other income',
            none: 'None',
          },
        },
        capital_gains: {
          headerText:
            'You indicated that you had the following capital gains (or losses) in {{fiscalYear}}. Please select one item to start with.',
          bodyText:
            'Did you have more than one capital gain (or loss)? Just pick one to start with. We’ll go through the other type of capital gain (or loss) once we’ve finished the first.',
          lightBoxLabel: 'Where do financial instruments belong?',
          lightBoxText:
            'As a general rule, the sale of financial instruments qualifies as capital gains and is typically reported under "sale of shares (or similar)".',
          answers: {
            crypto: 'Sale of crypto',
            precious_metals: 'Sale of precious metals',
            real_estate: 'Sale of real estate',
            shares: 'Sale of shares (or similar)',
            objects: 'Sale of objects',
            other: 'Sale of other capital gains',
            none: 'None',
          },
        },
        assets: {
          headerText:
            'We’re now going to make a list of your assets in {{fiscalYear}}. You have to select each item separately.',
          bodyText:
            'If you had bank accounts in two countries, you have to create two items. If you had two houses in the same country, you also have to create two items. Just pick one item to start with. We’ll go through the other assets once we’ve finished the first.',
          lightBoxLabel: 'What do some of these terms mean?',
          lightBoxText: `### What do some of these terms mean?
- **Shares in a real estate company:** shares or interests in a company, partnership, or trust that consists primarily of real estate. 
- **Receivables:** money that is owed to you by another party which you have a legal right to collect.
- **Derivatives:** financial contracts that derive their value from an underlying asset, such as a stock or commodity. They can be used to mitigate risks, speculate, or for other purposes. 
- **High-value art:** art that is valued at a significant amount, often believed to be a form of investment.`,
          answers: {
            cash: 'Cash',
            bank_account: 'Bank accounts',
            real_estate: 'Real estate',
            shares_exchange: 'Shares listed on a stock exchange',
            shares_real_estate: 'Shares in a real estate company',
            shares_regular: 'Regular shares in a company',
            receivables: 'Receivables',
            value_art: 'High-value art',
            metals: 'Precious metals',
            other: 'Other',
            skip: "I didn't have any assets, let's skip this section",
          },
        },
        submitButtonText: 'Continue',
      },
      submit: {
        title:
          'You made it. This is the end of the Income & Assets questionnaire',
        subtitle:
          'Click "Submit" if you\'ve entered all your {{fiscalYear}} income and assets. You\'ll be taken back to the main overview to continue with the next steps.',
        button: 'Submit',
        modal: {
          title: 'Oops it seem like you forgot some things...',
          subtitle:
            'It seems that you have items that are still in progress. You must complete those items to submit the Income & Assets questionnaire. To complete these items please navigate back to the section with the in-progress items.',
          confirm_text: 'Ok',
        },
      },
    },
    general_error:
      'Oops! Something went wrong!\nOur technical staff have been automatically notified and will be looking into this!',
    advice_request_invalid:
      'Incorrect advice id or the advice request has been archived',
    general_error_route_not_found: 'Page does not exist',
    generic_error: {
      title: 'Oops! Something went wrong!',
      description:
        'Our technical staff have been automatically notified and will be looking into this!',
      action: 'Try again',
    },
    invoices,
  },
  welcome_box: {
    title: 'Tytle: the simple solution for your taxes',
    how_works: '',
    questions: 'Creating a request only takes a few minutes',
    quick_call:
      'We can assist with a range of services: advice, filings, bookkeeping, visa, and more',
    tax_experts:
      "We'll get back to you with a quote as soon as possible, no commitment",
    note: '',
    lets_get_started: 'Let’s get started',
  },
  welcome_box_advice: {
    title: 'International tax advice with Tytle',
    how_works: 'How does it work?',
    questions:
      'Start a new advice request and answer some questions. By doing so we can review your information before getting back to you.',
    quick_call:
      "If you haven't done one yet, a free consultation call will be scheduled.",
    tax_experts:
      'Our local Tytle partners will send you a proposal with a plan and a fee estimate.',
    review:
      'You review and accept when the conditions are satisfactory, and the advice work can start!',
    note: 'It doesn’t matter what your tax situation is (national or international) and how well-prepared you are at this moment (no clue or very informed). Just go with the flow!',
    lets_get_started: 'Let’s get started',
  },
  header: {
    back_home: 'Back to Home',
    back_login: 'Back to Login',
    help: 'Help',
    back_tax_year: 'Back to Tax Year',
    back_overview: 'Go back to {{fiscalYear}} overview',
    back_income_categories: 'Back to Income Questionnaire',
    back_summary: 'Back to Summary',
    back_filings: 'Back to Filings',
    back_advice: 'Back to all advice items',
  },
  is_mobile: 'The Tytle application is not made for mobile-phone use',
  progress_bar: {
    menu: 'Menu',
    employment: '(self-) employment',
    social_contributions: 'social contributions',
    students: 'students',
    welfare: 'welfare / social benefits',
    capital_gains: 'capital gains',
    dividends: 'dividends',
    interest: 'interest',
    pensions_annuities: 'pension / annuities',
    misc_income: 'misc income',
    assets: 'assets',
    submit: '',
  },
  card_title: {
    self_employed: 'self employed',
    worked_other: 'worked other',
    employed: 'employed',
  },
  select_countries: {
    joint_filing: {
      title: 'Would you like to do a joint filing?',
      description: `Depending on the country, you can add a spouse, qualifying dependent or other fiscal partner to your filing. This could lead to a lower effective tax rate than if you were to file alone.\n\nIf you select “yes” we will contact you for more details, and help you find out if a joint filing is possible and preferable.`,
      lightbox: '',
      lightboxText: '',
      answers: {
        yes: 'Yes',
        no: 'No',
      },
      submitButton: 'Continue',
    },
    partner_email: {
      title:
        'What is the email address of your (potential) partner for a joint filing?',
      description:
        'Once we know that a joint filing is possible, and after you indicated that you prefer to file jointly, we will ask your (fiscal) partner to fill out a questionnaire as well. Below you can leave your (fiscal) partner’s e-mail address for that purpose.',
      placeholder: 'Enter here',
      invalid_email: 'Invalid email',
      submitButton: 'Continue',
    },
    partner_name: {
      title: 'What is your (potential) fiscal partner’s name?',
      description:
        'Once we know that a joint filing is possible, and after you indicated that you prefer to file jointly, we will ask your (fiscal) partner to fill out a questionnaire as well. Below you can leave your (fiscal) partner’s e-mail address for that purpose.',
      placeholder: 'Enter here',
      submitButton: 'Continue',
    },
    filing_situation: {
      title:
        'Our tax experts will assist you with your tax return in {{country}} for {{fiscalYear}}. Please confirm.',
      description:
        'In case you already have a tax advisor in {{country}} who knows your personal situation well, you may connect them with us. In this case, Tytle and/or Tytle’s partner tax advisor(s) will communicate directly with your tax advisor when needed.',
      lightbox: '',
      lightboxText: '',
      answers: {
        use_tytle_tax_advisor: 'Yes, let’s go ahead.',
        connect_own_tax_advisor:
          'I already have my own tax advisor in {{country}}.',
        skip_tax_filing:
          'I’ll prepare and file the tax returns in {{country}} myself.',
        file_but_unsure_tax_advisor:
          'I’m not sure yet if I want to use Tytle for my tax filing in {{country}}',
      },
      submitButton: 'Continue',
    },
    advisor_email: {
      title: 'What is the email address of your tax advisor in {{country}}?',
      description:
        'We will first analyze your situation and ask your confirmation to involve your tax advisor before contacting them.',
      placeholder: 'Enter here',
      submitButton: 'Continue',
    },
    advisor_name: {
      title: 'What is the name of your tax advisor in {{country}}?',
      description:
        'We will first analyze your situation and ask your confirmation to involve your tax advisor before contacting them.',
      placeholder: 'Enter here',
      submitButton: 'Continue',
    },
    filing_country: {
      title:
        'Our tax experts will assist you with your tax return in {{country}} for {{fiscalYear}}. Please confirm.',
      description:
        'In case you already have a tax advisor in {{country}} who knows your personal situation well, you may connect them with us. In this case, Tytle and/or Tytle’s partner tax advisor(s) will communicate directly with your tax advisor when needed.',
      lightbox: '',
      lightboxText: '',
      answers: {
        no_tax_advisor:
          'No thanks, I prefer to be connected to a tax advisor in {{country}}, selected by Tytle',
        connect_tax_advisor:
          'I want to connect my own tax advisor with Tytle for my filing in {{country}}',
        no_filing: 'I don’t want to use Tytle for my tax filing in {{country}}',
        unsure:
          'I’m not sure yet if I want to use Tytle for my tax filing in {{country}}',
      },
      submitButton: 'Continue',
    },
    thank_you: {
      title: 'Thank you! That’s it for now',
      description: 'Click “Continue” to return to the main screen',
      submitButton: 'Continue',
    },
  },
  stripe_connect: {
    title:
      'Connect your bank account details to receive payouts from Tytle via <a href="https://stripe.com/en-pt/legal/connect-account">Stripe</a>',
    button: 'Start',
    footnote: `By connecting your bank information with Stripe, you're enabling Tytle to process payments on your behalf. The payments you receive from clients (minus a small Stripe transaction and processing fee) will be transferred directly to your Stripe-connected account. You have the flexibility to receive these payouts from Stripe on a bi-weekly, monthly, or custom schedule, and the funds will then be transferred to your connected bank account.`,
  },
  stripe_refresh: {
    title:
      'Your link to connect your bank account to <a href="https://stripe.com/en-pt/legal/connect-account">Stripe</a> has expired, please click the button below to try again',
    button: 'Start',
    footnote: `By connecting your bank information with Stripe, you're enabling Tytle to process payments on your behalf. The payments you receive from clients (minus a small Stripe transaction and processing fee) will be transferred directly to your Stripe-connected account. You have the flexibility to receive these payouts from Stripe on a bi-weekly, monthly, or custom schedule, and the funds will then be transferred to your connected bank account.`,
  },
  stripe_success: {
    title:
      'Your bank account has been successfully linked to Stripe. You can close this window',
  },
  verify_with_us: {
    intro: {
      title: 'The last step before filing your tax returns...',
      description:
        'Because there is a lot to consider, we need to have a quick 10-15 minute call with you and have you review a preliminary rough estimate of the total cost.\n\nOn the call our international tax experts will look at all the information you have entered and will see if there are any items that we are missing. You will also have the opportunity to ask any outstanding questions that you might have.\n\nPlease click “Continue” to review the estimated costs for your filings. We will update these costs to reflect your situation more accurately after we have finished our call, and we will remove any items that are not necessary to take into account for your tax filings.\n\nAfter reviewing the rough cost estimate you will proceed to a screen to schedule the call with one of Tytle’s experts.',
    },
    schedule: {
      title: 'Schedule a call with a Tytle expert',
      description: 'After scheduling your call, click "Continue”.',
      schedule_button: 'Schedule a call',
    },
    in_progress: {
      title: 'Thank you for scheduling a call with us',
      description:
        "We'll reach out to you soon to discuss your situation and provide you with the best possible support and advice.\n\nIf you have any questions or concerns before your call, don't hesitate to reach out to us. We're here to help, and we look forward to speaking with you soon.",
    },
  },
  submit_payment: {
    summary: {
      title: "Let's review and make a downpayment to proceed",
      subtitle:
        'Prices shown in {{currency}}. Change currency by clicking <u>here.</u>',
      complexity_level: 'Your {{fy}} filing complexity level:',
      resident_filings: 'Resident Filings',
      non_resident_filings: 'Non-Resident Filings',
      estimated_total: 'Estimated total:',
      updated_total: 'Updated estimated total:',
      deposit: 'Deposit due now:',
      discount_placeholder: 'Discount code',
      apply: 'apply',
      deposit_note:
        "Confirm your commitment to filing your taxes with partner tax experts by making a deposit payment of {{currency}}{{deposit}}. This deposit will be credited towards the final amount payable. Simply click 'Continue' to proceed.",
      foot_note:
        '*: VAT included. Please note: these prices are current estimates and therefore not final and non-binding. They can be subject to change, based on the availability of partners and (re)evaluations of the complexity of your case.',
      terms_agreement:
        'I agree to the <a href="https://tytle.notion.site/Terms-and-Conditions-Tytle-e9eee6ca01d8423489346a02b31fa2c5">terms & conditions</a> of Tytle',
      accept_to_move_forward: 'accepting is required to move forward',
      deal_error: 'invalid or expired discount code',
      price_range: '{{currency}}{{min}} - {{currency}}{{max}}*',
    },
  },
  section_submitted: 'This section was successfully submitted',
  languages,
  partnerinfo,
};

export default en;
