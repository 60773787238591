import React, { MouseEvent } from 'react';
import Icon from '../Icon';
import SpinnerLoading from './../SpinnerLoading';
import { IconsList } from '../Icon/icons/enum';
import { colors } from '../theme';
import './index.css';
import { IIconProps } from '../Icon/interface';
export interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  size: 'default' | 'small' | 'medium' | 'large' | 'xlarge';
  variant: 'primary' | 'secondary' | 'tertiary' | 'reject';
  disabled?: boolean;
  icon?: IconsList;
  iconSize?: 'default' | 'medium' | 'large';
  iconPosition?: 'right' | 'left';
  loading?: boolean;
  className?: string;
  iconType?: IIconProps['type'];
  onClick: (event: MouseEvent<HTMLElement>) => void;
  whiteSpace?: boolean;
}

const Button = ({
  id,
  children,
  size,
  variant,
  disabled,
  icon,
  iconSize,
  iconPosition = 'left',
  loading = false,
  className,
  iconType = 'circle',
  onClick,
  whiteSpace,
  type = 'button',
}: IButton) => {
  const handleIconColor = () => {
    if (disabled) {
      return colors.neutral.textDisabled;
    }

    if (variant === 'primary') {
      return colors.base.white;
    }

    return colors.interactive.text;
  };

  const getIconSize = () => {
    if (iconSize === 'default') {
      return 14;
    }

    if (iconSize === 'medium') {
      return 21;
    }

    if (iconSize === 'large') {
      return 32;
    }

    if (size === 'default') {
      return 14;
    }

    return 21;
  };

  return (
    <button
      className={`button ${className} ${variant} ${size} ${
        Boolean(disabled) && 'disabled'
      } ${Boolean(icon) && 'icon'} ${
        !loading ? (iconPosition === 'left' ? 'iconLeft' : 'iconRight') : ''
      } ${
        loading ? 'pointer-events-none' : 'pointer-events-auto'
      } flex items-center justify-center rounded-lg transition-all`}
      disabled={disabled}
      onClick={onClick}
      type={type}
      id={id}
    >
      {icon && !loading && (
        <div className="iconContainer">
          <Icon
            name={icon}
            color={handleIconColor()}
            width={getIconSize()}
            height={getIconSize()}
            type={iconType}
          />
        </div>
      )}
      <div className="absolute">
        <SpinnerLoading
          variant={variant}
          active={loading && !disabled}
          width={size === 'default' ? 14 : 21}
          height={size === 'default' ? 14 : 21}
        />
      </div>
      <span
        className={`${loading && !disabled ? 'invisible' : ''} ${
          whiteSpace ? 'whitespace-nowrap' : ''
        }`}
      >
        {children}
      </span>
    </button>
  );
};

export default Button;
