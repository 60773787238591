import AxiosClass from '.';
import env from '../env';
import { IAdiviceAnswers } from '../screens/AppScreens/AdviceAnswers/consts';
import { IAdviceItem } from '../screens/AppScreens/AdviceDetails/tabs/Proposals/interface';

export interface ISaveFiles {
  name: string;
  title: string;
  adviceId?: string;
  userId?: string;
}

export interface IDossierItem {
  title: string;
  description: string;
  type: string;
  createdAt: string;
  url?: string;
}

export interface IAdvisor {
  userId: string;
  firstName: string;
  lastName: string;
  status: string;
  picture: string;
  countries: string[];
  summary: string;
  languages: string[];
}

export interface IExpert {
  picture: string;
  summary: string;
  location: string;
  full_name_company: string;
  first_name_contact_person: string;
  main_countries: string[];
  advisors: IAdvisor[];
}

export interface IQuestionnaireResponse {
  [key: string]: QuestionResponse;
}

interface QuestionResponse {
  order: number;
  answer: string | string[] | null;
  question: string;
  displayType: string;
  questionType: QuestionType;
}

type QuestionType =
  | 'Country'
  | 'FreeTextBig'
  | 'Radio'
  | 'Date'
  | 'OtherFreeTextSmall'
  | 'Card';

class AdvicesService extends AxiosClass {
  async getDossierItems(adviceId: string): Promise<IDossierItem[]> {
    const { data } = await this.axiosInstance.get(
      `${env.APP_API}/dossier/annotation/${adviceId}`
    );
    return data;
  }

  async getAdviceExperts(adviceId: string): Promise<IExpert[]> {
    const { data } = await this.axiosInstance.get(
      `${env.APP_API}/advices/experts/${adviceId}`
    );
    return data;
  }

  async getAdvices() {
    return this.axiosInstance.get(`${env.APP_API}/advice`);
  }

  async getAdvice(adviceId: string): Promise<{
    data: IAdviceItem;
  }> {
    return this.axiosInstance.get(`${env.APP_API}/client/advices/${adviceId}`);
  }

  async getChats(adviceid: string) {
    const { data } = await this.axiosInstance.get(`${env.APP_API}/advice/chats/${adviceid}`);
    return data
  }

  async getAdviceDetails(adviceid: string) {
    return this.axiosInstance.get(`${env.APP_API}/storage/advice/${adviceid}`);
  }

  async getQA(adviceId: string): Promise<IAdiviceAnswers[]> {
    const { data } = await this.axiosInstance.get(
      `${env.APP_API}/public/advicesummary/${adviceId}`
    );

    return data;
  }

  async getPublicQuestionnaire(
    clientId: string
  ): Promise<IQuestionnaireResponse> {
    const { data } = await this.axiosInstance.get(
      `${env.APP_API}/public/questionnaire/${clientId}`
    );

    return data;
  }

  async getClientQuestionnaire(): Promise<IQuestionnaireResponse> {
    const { data } = await this.axiosInstance.get(
      `${env.APP_API}/client/questionnaire`
    );

    return data;
  }

  async getQuestionnaireForAdvisor(adviceId: string): Promise<IQuestionnaireResponse> {
    const { data } = await this.axiosInstance.get(
      `${env.APP_API}/advisor/questionnaire/${adviceId}`
    );

    return data;
  }

  async getQuestionnaireForSupport(adviceId: string): Promise<IQuestionnaireResponse> {
    const { data } = await this.axiosInstance.get(
      `${env.APP_API}/support/questionnaire/${adviceId}`
    );

    return data;
  }

  async getAdvisorChats(advisorId: string): Promise<any> {
    const { data } = await this.axiosInstance.get(
      `${env.APP_API}/support/advisor/chats/${advisorId}`
    );

    return data;
  }

  async deleteFile({
    adviceId,
    questionId,
    file,
  }: {
    adviceId: string;
    questionId: string;
    file: string;
  }) {
    return await this.axiosInstance.patch(`${env.APP_API}/advice/uploads/`, {
      adviceId,
      questionId,
      name: file,
    });
  }

  async addAdvice() {
    return this.axiosInstance.patch(`${env.APP_API}/advice/inititem`);
  }

  async getAdviceItem({ adviceId }: { adviceId: string }) {
    return this.axiosInstance.patch(`${env.APP_API}/advice/item`, {
      adviceId,
    });
  }

  async getNextQuestion(payload: {
    adviceId: string;
    roadmap: any;
    lastQuestionAnswered?: { id: string; key: string; answers: string[] };
  }) {
    return this.axiosInstance.patch(`${env.APP_API}/advice/nextQuestion`, {
      ...payload,
    });
  }

  async submitAdviceItem(payload: {
    adviceId: string;
    roadmap: any;
    lastQuestionAnswered?: { id: string; key: string; answers: string[] };
  }) {
    return this.axiosInstance.post(`${env.APP_API}/advice/submitadvice`, {
      ...payload,
    });
  }

  async cleanQuestion(payload: {
    adviceId: string;
    roadmap: any;
    lastQuestionAnswered?: { id: string; key: string; answers: string[] };
  }) {
    return this.axiosInstance.post(`${env.APP_API}/advice/cleanquestion`, {
      ...payload,
    });
  }

  async cleanItem(payload: { adviceId: string }) {
    return this.axiosInstance.post(`${env.APP_API}/advice/cleanadvice`, {
      ...payload,
    });
  }

  async getStarted() {
    return this.axiosInstance.get(`${env.APP_API}/advice/welcome`);
  }

  async handleWelcome() {
    return this.axiosInstance.patch(`${env.APP_API}/advice/welcome`);
  }

  async saveFiles(files: ISaveFiles[]) {
    return this.axiosInstance.put(`${env.APP_API}/storage`, {
      files,
    });
  }

  async deleteFiles(adviceId: string, fileName: string) {
    return this.axiosInstance.delete(
      `${env.APP_API}/storage/${adviceId}/${fileName}`
    );
  }

  async requestDeposit(proposalId: string): Promise<{
    data: { items: IAdviceItem[] };
  }> {
    return this.axiosInstance.patch(
      `${env.APP_API}/advisor/proposals/${proposalId}`
    );
  }

  async requestFinalPayment(proposalId: string): Promise<{
    data: { items: IAdviceItem[] };
  }> {
    return this.axiosInstance.patch(
      `${env.APP_API}/advisor/proposals/final-payment/${proposalId}`
    );
  }

  async acceptRejectProposal(
    proposalId: string,
    action: string,
    clientComment?: string
  ): Promise<{
    data: { chatId: string };
  }> {
    return this.axiosInstance.post(
      `${env.APP_API}/client/proposals/${proposalId}`,
      { action, clientComment }
    );
  }

  async submitQuestionnnaire(payload: any): Promise<{
    data: any;
  }> {
    return this.axiosInstance.put(
      `${env.APP_API}/client/questionnaire`,
      payload
    );
  }

  async getQuestionnnaire(): Promise<{
    data: IQuestionnaireResponse;
  }> {
    return this.axiosInstance.get(
      this.sessionData
        ? `${env.APP_API}/client/questionnaire`
        : `${env.APP_API}/public/questionnaire`
    );
  }

  async getPaymentProposal({ currency }: { currency: string }): Promise<{
    data: any;
  }> {
    return this.axiosInstance.post(`${env.APP_API}/advices/proposals/payment`);
  }
}

export default AdvicesService;
